import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import image from './assets/dreamLogo.png';

function VideoPage() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="fixed" style={{ background: '#FFFF', zIndex: 1100 }}>
        <Toolbar>
          <a href="https://thedreambox.studio/" target="_blank" rel="noopener noreferrer">
            <img src={image} alt="Logo" style={{ width: 130, height: 'auto', marginRight: 15 }} />
          </a>
          <Typography variant="h6" component="div">
            Dreambox Live
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Roboto, Arial, sans-serif',
          fontSize: '1rem',
          color: '#b89043',
          marginTop: isMobile ? '75px' : '100px',
          marginBottom: isMobile ? '5px' : '10px',
          textAlign: 'center',
          padding: '5px',
          zIndex: 1
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Sudarshan & Varshini
        </Typography>
        <Typography variant="body1" component="div">
          08.08.2024
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px 0',
          boxSizing: 'border-box',
          marginTop: isMobile ? '2px' : '90px',
          backgroundColor: '#FFFF',
          height: `calc(100vh - 64px)`,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: isMobile ? '100vw' : '100%',
            height: isMobile ? '100%' : '0',
            paddingBottom: isMobile ? '0' : '56.25%',
            maxWidth: '100%',
            backgroundColor: '#FFFF',
          }}
        >
          <iframe
            title="Dreambox Live"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/WRG5BnLK7rg?si=aJ9dMpdLM92cReRS"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </Box>
      </Box>
    </div >
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/sudarshan&varshini" replace />} />
        <Route path="/sudarshan&varshini" element={<VideoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
